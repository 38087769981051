import store from '@/store';

export default class DownloadTransformer {
    private static uri: string = store.getters['siteConfigState/getBackend'];

    public static transform(downloads: Record<string, any>): Record<string, any>[] {
        const builds: Record<string, any>[] = [];

        Object.keys(downloads).forEach(download => {
            const build: Record<string, any> = {
                title: this.getTitle(download),
                downloads: [],
            };

            if (downloads[download]) {
                switch (download) {
                    case 'prodBuild':
                        Object.keys(downloads[download]).forEach(key => {
                            const prodBuild: Record<string, any> | null = downloads[download][key];

                            if (prodBuild) {
                                build.downloads.push(this.transformProdBuild(prodBuild, key));
                            }
                        });

                        break;
                    default:
                        Object.keys(downloads[download]).forEach(key => {
                            const link: string = downloads[download][key];

                            if (link) {
                                build.downloads.push({
                                    title: this.getDownloadTitle(key),
                                    link,
                                });
                            }
                        });

                        break;
                }
            }

            builds.push(build);
        });

        return builds;
    }

    private static transformProdBuild(build: Record<string, any>, key: string): Record<string, any> {
        switch (key) {
            case 'usui':
            case 'usuiu2':
            case 'usuiv703up2':
            case 'usuiv710up':
                build.title = `Unified StorNext User Interface - Combined Package - v${build.version}`;
                build.link = `${this.uri}/api/v2/systems/${key}/binary-downloads/${build.id}`;

                break;
            case 'usuiryo':
            case 'usuiryoip703':
            case 'usuiryo710':
            case 'usuiryo1001':
            case 'usuiryo1001up':
                build.title = `${build.description} - v${build.version}`;
                build.link = `${this.uri}/api/v2/systems/usuiryo/binary-downloads/${build.id}`;

                break;
        }

        if (build.disabled) {
            build.link = '';
        }

        return build;
    }

    private static getDownloadTitle(title: string): string {
        switch (title) {
            case 'usui':
                return 'Unified StorNext User Interface';
            case 'usbe':
                return 'Unified StorNext User Interface Server';
            case 'apiGateway':
                return 'Unified StorNext API Gateway';
        }

        return title;
    }

    private static getTitle(key: string): string {
        switch (key) {
            case 'masterBuild':
                return 'Master Build Downloads';
            case 'prodBuild':
                return 'Release Build Downloads';
            case 'tagBuild':
                return 'Tag Build Downloads';
        }

        return key;
    }
}
