import axios from '@/services/api/Common';
import {AxiosPromise, AxiosResponse} from 'axios';
import store from '@/store';

export default {
    getSystems: () => {
        const api = axios.create({
            withCredentials: true,
        });
        return api.get(store.getters['siteConfigState/getBackend'] + '/api/v2/systems',
                       {
                           headers:
                    { 'Content-Type': 'application/json'},
                       });
    },

    getDetails: (systemId: string): AxiosPromise => {
        const api = axios.create({
            withCredentials: true,
        });
        return api.get(store.getters['siteConfigState/getBackend'] + '/api/v2/systems/' + systemId + '/details',
                       {
                           headers:
                    {'Content-Type': 'application/json'},
                       });

    },

    getDownloadsList: (systemId: string) => {
        const api = axios.create({
            withCredentials: true,
        });
        return api.get(store.getters['siteConfigState/getBackend'] + '/api/v2/systems/' + systemId + '/downloads',
                       {
                           headers:
                    {'Content-Type': 'application/json'},
                       });
    },

    getDownload: (systemId: string, downloadId: string, api: any) =>
        api.get(store.getters['siteConfigState/getBackend'] +
            '/api/v2/systems/' + systemId + '/downloads/' + downloadId,
                {
                    responseType: 'blob',
                    headers:
                    { Accept : 'application/octet-stream'},
                }),

    removeFromAccount: (systemId: string, isOwner: boolean) => {
        const api = axios.create({
            withCredentials: true,
        });

        if (store.getters['profile/isAdmin']) {
            isOwner = true;
        }

        let url: string = `${store.getters['siteConfigState/getBackend']}/api/v2/systems/${systemId}`;

        if (! isOwner) {
            url += '/user';
        }

        return api.delete(url, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    },

    addSystemWithLicense: (licenseFile: File|Blob): AxiosPromise => {
        const api = axios.create({
            withCredentials: true,
        });

        const $data = new FormData();
        $data.append('license', licenseFile);

        return  api.post(store.getters['siteConfigState/getBackend'] + '/api/v2/systems', $data);
    },

    isOwner: (systemID: string): AxiosPromise => {
        const api = axios.create({
            withCredentials: true,
        });

        return api.get(`${store.getters['siteConfigState/getBackend']}/api/v2/system/${systemID}/user/is-owner`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    },

    getUsuiDownloads: (): AxiosPromise => {
        const api = axios.create({
            withCredentials: true,
        });

        return api.get(`${store.getters['siteConfigState/getBackend']}/api/v2/downloads/usui`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    },
};
