




























import Vue from 'vue';
import SystemApi from '@/services/api/System';
import { Component } from 'vue-property-decorator';
import DownloadTransformer from '@/transformers/DownloadTransformer';

@Component
export default class UsuiDownloads extends Vue {
    public loading: boolean = true;

    public downloads: Record<string, any>[] = [];

    public mounted(): void {
        this.getDownloads();
    }

    public download(link: string): void {
        if (link.length) {
            window.open(link);
        }
    }

    public showDownloads(): boolean {
        return Object.keys(this.downloads).length > 0;
    }

    public getDownloads(): void {
        SystemApi.getUsuiDownloads().then(response => {
            this.downloads = DownloadTransformer.transform(response.data.usui_downloads);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            this.loading = false;
        });
    }
}
