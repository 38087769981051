













import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import UsuiDownloads from '@/components/System/UsuiDownloads.vue';

@Component({
    components: {
        UsuiDownloads,
    },
})
export default class Downloads extends Vue {
}
